<template>
  <div>
    <!--      <nav-bar :is-white-navbar="false" :navLight="true"></nav-bar>-->
    <!--    <GnbDownMenu :headers="headers" />-->

    <!-- 임시 메뉴 -->
    <!--    <b-container>-->
    <!--      <b-row>-->
    <!--        <b-col class="text-center mt-2">-->
    <!--          <b-link to="/">-->
    <!--            <img src="@/assets/images/main/top_logo.png" />-->
    <!--          </b-link>-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--      <b-nav align="center">-->
    <!--        <b-nav-item href="https://pinx-golf-0211.dalsoft.net/" target="_blank">핀크스 골프 클럽</b-nav-item>-->
    <!--        <b-nav-item href="https://pinx-podo-0211.dalsoft.net/" target="_blank">포도호텔</b-nav-item>-->
    <!--        <b-nav-item href="https://pinx-annex-0211.dalsoft.net/" target="_blank">디아넥스호텔</b-nav-item>-->
    <!--        <b-nav-item to="/p/HrAbout">인재채용</b-nav-item>-->
    <!--        <b-nav-item to="/p/Notice">공지사항</b-nav-item>-->
    <!--      </b-nav>-->
    <!--    </b-container>-->
    <Gnb :headers="headers" v-if="!isMemberReserve" />
    <div class="contents">
      <router-view />
    </div>
    <div id="footer" v-if="!isMemberReserve">
      <pinx-footer class="webFooter"></pinx-footer>
      <PinxFooterMobile class="mobileFooter"></PinxFooterMobile>
    </div>
  </div>
</template>

<script>
import PinxFooter from '@/components/PinxFooter';
import PinxFooterMobile from '@/components/PinxFooter_mobile';
import Gnb from '@/components/Gnb';

export default {
  components: {Gnb, PinxFooter, PinxFooterMobile},
  data() {
    return {
      isLang: true,
      headers: null,
      headers_ko: [
        {
          // topMenu: 'ABOUT',
          topMenu: 'PINX GOLF CLUB',
          topMenuLink: 'https://pinxgc.thepinx.co.kr/',
        },
        {
          // topMenu: 'ABOUT',
          topMenu: 'PODO HOTEL',
          topMenuLink: 'https://podo.thepinx.co.kr/',
        },
        {
          // topMenu: 'ABOUT',
          topMenu: 'THE ANNEX',
          topMenuLink: 'https://theannex.thepinx.co.kr/',
        },
        {
          // topMenu: 'ABOUT',
          topMenu: 'P-LEISURE',
          topMenuLink: 'https://pleisure.thepinx.co.kr/',
        },
        // // {
        // //   // topMenu: 'ABOUT',
        // //   topMenu: '비오토피아',
        // //   topMenuLink: '/p/Biotopia',
        // // },
        // {
        //   // topMenu: 'ABOUT',
        //   topMenu: '인재채용',
        //   topMenuLink: '/p/HrAbout',
        // },
      ],
      headers_en: [
        {
          // topMenu: 'ABOUT',
          topMenu: 'PINX GOLF CLUB',
          topMenuLink: 'https://pinxgc.thepinx.co.kr/',
        },
        {
          // topMenu: 'ABOUT',
          topMenu: 'PODO HOTEL',
          topMenuLink: 'https://podo.thepinx.co.kr/',
        },
        {
          // topMenu: 'ABOUT',
          topMenu: 'THE ANNEX',
          topMenuLink: 'https://theannex.thepinx.co.kr/',
        },
        // // {
        // //   // topMenu: 'ABOUT',
        // //   topMenu: '비오토피아',
        // //   topMenuLink: '/p/Biotopia',
        // // },
        // {
        //   // topMenu: 'ABOUT',
        //   topMenu: '인재채용',
        //   topMenuLink: '/p/HrAbout',
        // },
      ],
      headers_jp: [
        {
          // topMenu: 'ABOUT',
          topMenu: 'PINX GOLF CLUB',
          topMenuLink: 'https://pinxgc.thepinx.co.kr/',
        },
        {
          // topMenu: 'ABOUT',
          topMenu: 'PODO HOTEL',
          topMenuLink: 'https://podo.thepinx.co.kr/',
        },
        {
          // topMenu: 'ABOUT',
          topMenu: 'THE ANNEX',
          topMenuLink: 'https://theannex.thepinx.co.kr/',
        },
        // // {
        // //   // topMenu: 'ABOUT',
        // //   topMenu: '비오토피아',
        // //   topMenuLink: '/p/Biotopia',
        // // },
        // {
        //   // topMenu: 'ABOUT',
        //   topMenu: '인재채용',
        //   topMenuLink: '/p/HrAbout',
        // },
      ],
    };
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.headers = this.headers_ko;
      } else if (newVal === 'en') {
        this.headers = this.headers_en;
      } else if (newVal === 'ja') {
        this.headers = this.headers_jp;
      } else {
        this.headers = this.headers_ko;
      }
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  computed: {
    isMemberReserve() {
      return (
        this.$router.currentRoute.fullPath === '/p/mall' ||
        this.$router.currentRoute.fullPath === '/p/mall/' ||
        this.$router.currentRoute.fullPath === '/p/MemberReserv'
      );
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
};
</script>
<style lang="scss">
.contents {
}
.webFooter {
  display: block;
}
.mobileFooter {
  display: none;
}
@media (max-width: 992px) {
  .webFooter {
    display: none;
  }
  .mobileFooter {
    display: block;
  }
}
</style>
