<template>
  <div class="footer">
    <div class="footer-menu">
      <div v-show="isLang === 0">
        <div class="footer-menu-top row d-flex justify-content-center">
          <div class="menu-top-item text-center borderless pt-2 pb-2">
            <router-link to="/p/Introducepinx"><p>회사소개</p></router-link>
          </div>
          <div class="menu-top-item text-center borderless pt-2 pb-2">
            <router-link to="/p/Pinxpolicy/1"><p>이용약관</p></router-link>
          </div>
          <div class="menu-top-item text-center borderless pt-2 pb-2">
            <a href="https://ethics.sk.co.kr/" target="_blank"><p>윤리경영</p></a>
          </div>
          <!--        <div class="menu-top-item text-center borderless pt-2 pb-2" v-show="isLang === 0">-->
          <!--          <router-link to="/p/Pinxpolicy/2"><p>윤리경영</p></router-link>-->
          <!--        </div>-->
          <div class="menu-top-item text-center borderless pt-2 pb-2">
            <router-link to="/p/QnaMain"><p>고객의 소리</p></router-link>
          </div>
          <!--        <div class="menu-top-item text-center borderless pt-2 pb-2" v-show="isLang === 0">-->
          <!--          <a href="https://ethics.sk.co.kr/" target="_blank"> <p>상담&제보</p></a>-->
          <!--        </div>-->
          <div class="menu-top-item text-center borderless pt-2 pb-2">
            <router-link to="/p/Direction"><p>오시는 길</p></router-link>
          </div>
          <div class="menu-top-item text-center borderless pt-2 pb-2">
            <a href="https://skpinx.skcareers.com/" target="_blank">인재채용</a>
          </div>
        </div>

        <div class="footer-menu-bottom row d-flex no-gutters">
          <div class="col-6 text-center border pt-2 pb-2">
            <router-link to="/p/Pinxpolicy/0"><p style="font-weight: bolder">개인정보 처리 방침</p></router-link>
          </div>
          <div class="col-6 text-center border pt-2 pb-2">
            <router-link to="/p/Pinxpolicy/3"><p>이메일 무단 수집 거부</p></router-link>
          </div>
        </div>

        <div class="row d-flex no-gutters">
          <div class="col-6 text-center bg-light border">
            <button type="button" class="btn btn-primary btn-block">
              <router-link to="/p/EventMain" style="color: white"
                ><strong> 행사문의 </strong><b-icon icon="arrow-right-square" class="smallicon02"></b-icon
              ></router-link>
            </button>
          </div>
          <div class="col-6 text-center bg-light border">
            <button type="button" class="btn btn-primary btn-block">
              <router-link to="/p/MarketingMain" style="color: white">
                <strong> 마케팅 제휴 </strong><b-icon icon="arrow-right-square" class="smallicon02"></b-icon
              ></router-link>
            </button>
          </div>
        </div>
      </div>
      <div v-show="isLang === 1">
        <div class="footer-menu-bottom row d-flex no-gutters">
          <div class="col-6 text-center border pt-2 pb-2">
            <router-link to="/p/Pinxpolicy/0"><p style="font-weight: bolder">Privacy Policy</p></router-link>
          </div>
          <div class="col-6 text-center border pt-2 pb-2">
            <router-link to="/p/Pinxpolicy/2"><p>Ethical Management</p></router-link>
          </div>
          <div class="col-6 text-center border pt-2 pb-2">
            <a href="https://ethics.sk.co.kr/"><p>Speak up & Report</p></a>
          </div>
          <div class="col-6 text-center border pt-2 pb-2">
            <router-link to="/p/Direction">Location</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-main">
      <div class="row">
        <div class="col-12 d-flex linkPanel">
          <div class="col-3 linkItem">
            <a href="https://pinxgc.thepinx.co.kr" target="_blank">
              <img src="@/assets/images/common/logo/logo_golf_row_white.png" />
            </a>
          </div>
          <div class="col-3 linkItem">
            <a href="https://podo.thepinx.co.kr" target="_blank">
              <img src="@/assets/images/common/logo/logo_podo_row_white.png" />
            </a>
          </div>
          <div class="col-3 linkItem">
            <a href="https://theannex.thepinx.co.kr" target="_blank">
              <img src="@/assets/images/common/logo/logo_annex_row_white.png" />
            </a>
          </div>
          <div class="col-3 linkItem">
            <a href="https://pleisure.thepinx.co.kr" target="_blank">
              <img src="@/assets/images/common/logo/logo_pleisure_row_white.png" />
            </a>
          </div>
        </div>
      </div>
      <div>
        <hr />
      </div>
      <div class="descInfo">
        <div class="descinfoContent p-2 text-center">
          <p>
            <small v-html="$t('footer_desc1')"></small>
          </p>
        </div>
      </div>

      <div class="row d-flex col-12 justify-content-center pt-1 no-gutters">
        <div>
          <a href="https://www.instagram.com/podohotel_official/" title="Instagram" target="_blank">
            <button type="button" class="btn btn-circle mr-3">
              <b-icon scale="2%" icon="instagram" style="color: white"></b-icon></button
          ></a>
          <a href="https://www.facebook.com/profile.php?id=100015551734170" title="Facebook" target="_blank">
            <button type="button" class="btn btn-circle mr-3">
              <b-icon scale="2%" icon="facebook" style="color: white"></b-icon></button
          ></a>
          <a href="https://www.youtube.com/channel/UCCSZhYIdT8wPlRk2lVMxtyw" title="Youtube" target="_blank">
            <button type="button" class="btn btn-circle">
              <b-icon scale="2%" icon="youtube" style="color: white"></b-icon></button
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PinxFooter_mobile',
  data() {
    return {
      isLang: 0,
    };
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
hr {
  display: block;
  border-style: solid;
  border-width: 1px;
  width: 99%;
  margin: 0px;
}
.footer {
  padding-top: 0px;
  width: 100%;
  .footer-menu p {
    color: black;
  }
  .footer-menu-top {
    width: 100%;
    background-color: #e6e6e6;
    margin: 0rem;
    p {
      margin: 0;
      font-size: 0.8rem;
    }
    .menu-top-item {
      width: 20%;
    }
  }
  .footer-menu-bottom p {
    margin: 0;
  }
  .footer-menu-bottom {
    background-color: #e6e6e6;
  }

  .footer-main p {
    word-break: keep-all;
  }
}
.btn-circle {
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  background-color: #202942;
}
a,
p {
  background: transparent;
  text-decoration: none;
  color: inherit;
  font-size: 0.9rem;
}

.linkPanel {
  height: 5rem;
}

.linkItem {
  padding: 1rem;
  text-align: center;
  img {
    //width: 100%;
    height: 100%;
  }
}

.descInfo {
  display: flex;
  .descinfoContent {
    margin: 0 auto;
  }
}
</style>

<i18n>
{
  "ko": {
    "footer_desc1": "(63525) 제주 서귀포시 안덕면 산록남로 863<br />대표이사 : 정우성 <br />개인정보 보호 책임자 : 김대훈 이사(경영지원담당)<br />통신판매업신고 : 2009-제주안덕-0004호 <br />사업자등록번호 : 616-81-14083<br />법인명 : SK핀크스(주)<br />대표번호 : T 064-792-5200 | F 064-792-4901 <br />예약 T 064-792-8000 예약 M pinxbooking@skpinx.co.kr<br />Copyright 2022. SKPINX Co.,Ltd. All Rights Reserved."
  },

  "en":{
    "footer_desc1": "863, Sanroknamro, Anduk-myun, Seogwipo-si, Jeju Special Self-Governing<br/>Province, 63525 Korea | President : Jeong Wooseong<br/>Privacy Officer : Director Kim Daehoon(Management Support Division)<br/>Online Sales Reg. No. 2009-Jeju Anduk-0004<br/>Corporate Reg. No. 616-81-14083 | Corporate Name : SK PINX Co.,Ltd.<br/>A Key Number : Tel. +82-64-792-5200 Fax. +82-64-4901<br/>Reservation : Tel. +82-64-792-8000<br/>Mail. pinxbooking@skpinx.co.kr<br/>Copyright 2022. SKPINX Co.,Ltd. All Rights Reserved."
  },

  "ja": {
    "footer_desc1": "63525)チェジュとくべつじちどう西帰浦市安徳面山麓南室863<br/>代表取締役：カン・ソクヒョン<br/>通信販売業届 : 2009-Jeju Anduk-0004<br/>事業者登録番号 : 616-81-14083l法人名: SK ピンクス㈱<br/>代表電話: +82-64-792-5200 ファックス: +82-64-4901<br/>予約電話 : +82-64-792-8000<br/>Eメール : pinxbooking@skpinx.com<br/>Copyright 2022. SKPINX Co.,Ltd. All Rights Reserved."
  }
}
</i18n>
